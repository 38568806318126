"use client";

import Image from "next/image";
import { useRouter } from "next/navigation";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import logoDark from "@/images/logo-dark.svg";

const cookies = new Cookies();

const formSchema = z.object({
  password: z.string(),
});

export default function EnvAuthPage() {
  const router = useRouter();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });

  const handleLogin = (values) => {
    if (values.password !== process.env.NEXT_PUBLIC_ENV_PASSWORD) {
      form.setError("password", {
        message: "Invalid password",
      });
      return;
    }

    cookies.set("lb-env-auth", true, {
      path: "/",
      expires: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      //   secure: process.env.NODE_ENV === "production",
      //   sameSite: "strict",
    });

    router.push("/"); // Redirect to home page
    router.refresh(); // Force a refresh to re-evaluate the middleware
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="w-full max-w-sm">
        <Image
          className="mx-auto mb-6"
          src={logoDark}
          alt="LiveBinders"
          width={195}
          height={43}
        />
        <form
          onSubmit={form.handleSubmit(handleLogin)}
          className="border px-8 py-12 space-y-4 rounded-lg shadow"
        >
          <input
            className="w-full border p-2 rounded"
            placeholder="Enter password"
            type="password"
            {...form.register("password")}
          />
          {form.formState.errors.password && (
            <p className="text-red-500">
              {form.formState.errors.password.message}
            </p>
          )}
          <button
            type="submit"
            className="w-full bg-blue-500 text-white p-2 rounded"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
}
